import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Wrapper from 'components/Wrapper/Wrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { LastLocationProvider } from 'react-router-last-location';
import styles from 'pages/App/App.scss';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import Spinner from 'components/Spinner/Spinner';

import {
  HOME_ROUTE,
  CATEGORY_DETAIL_ROUTE,
  VERIFICATION_ROUTE,
  VERIFICATION_GUEST_ROUTE,
  GIFTCARD_CHECKOUT_ROUTE,
  FAQ_ROUTE,
  CAUSE_CREATE_ROUTE,
  CAUSE_DETAIL_ROUTE,
  CAMPAIGN_DETAIL_ROUTE,
  ORGANIZATION_DETAIL_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_ACTION_ROUTE,
  THANK_YOU_ROUTE,
  GIFTCARD_ROUTE,
  REGISTRATION_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_UP_GUEST_ROUTE,
  LOGIN_ROUTE,
  USER_SETTINGS,
  PAGE_NOT_FOUND,
  CAUSE_DONATION_ROUTE,
  GIFTCARD_SUCCESS_ROUTE,
  WELCOME_ROUTE,
  PROFILE_ROUTE,
  PROFILE_IMPACT_ROUTE,
  SUPPORTER_ROUTE,
  ORGANIZATION_PROFILE_SETTINGS_ROUTE,
  ORGANIZATION_PROFILE_IMPACT_ROUTE,
  INTRODUCTION_PAGE,
  PrivateRoute,
  GuestRoute,
  LOGOUT_ROUTE,
  NO_SUCCESS_ROUTE,
  INVITATION_ROUTE,
  CAUSE_EDIT_ROUTE,
  CAUSE_THANK_YOU_ROUTE,
  USER_COMPANY_SETTINGS,
  BLOG_DETAIL_PAGE, BLOG_LISTING_PAGE, CONTENT_PAGE, IMPACT_PAGE
} from 'routes';
import translate from 'lang';
import AppWrapper from '../../components/AppWrapper/AppWrapper';
import Redirector from '../Redirector/Redirector';

/* const Header = Loadable({
  loading: Spinner,
  loader: () =>
    import(/!* webpackChunkName:"Header" *!/ 'components/Header/Header'),
}); */
const NotFound = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"NotFound" */ 'pages/NotFound/NotFound'),
});
const Home = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"Home" */ 'pages/Home/Home'),
});
const Categories = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"Categories" */ 'pages/Categories/Categories'),
});
// const Blog = Loadable({
//   loading: Spinner,
//   loader: () => import(/* webpackChunkName:"Blog" */ 'pages/Blog/Blog'),
// });
// const BlogPost = Loadable({
//   loading: Spinner,
//   loader: () =>
//     import(/* webpackChunkName:"BlogPost" */ 'pages/BlogPost/BlogPost'),
// });
// const Page = Loadable({
//   loading: Spinner,
//   loader: () => import(/* webpackChunkName:"Page" */ 'pages/Page/Page'),
// });
const Footer = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"Footer" */ 'components/Footer/Footer'),
});
const Cause = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"Cause" */ 'pages/Cause/Cause'),
});
const CreateCause = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"CreateCause" */ 'pages/CreateCause/CreateCause'
    ),
});
const CreateCauseThankyou = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"CreateCauseThankyou" */ 'pages/CreateCauseThankyou/CreateCauseThankyou'
    ),
});
const SignUp = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"SignUp" */ 'pages/SignUp/SignUp'),
});
const SignUpGuest = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"SignUp" */ 'pages/SignUpGuest/SignUpGuest'),
});
const Organization = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"Organization" */ 'pages/Organization/Organization'
    ),
});
const GiftCard = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"GiftCard" */ 'pages/GiftCard/GiftCard'),
});
const GiftCardSuccess = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"GiftCardSuccess" */ 'pages/GiftCardSuccess/GiftCardSuccess'
    ),
});
const Registration = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"Registration" */ 'pages/Registration/Registration'
    ),
});
const Login = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"Login" */ 'pages/Login/Login'),
});
const ThankYou = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"ThankYou" */ 'pages/ThankYou/ThankYou'),
});
const UserProfile = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"UserProfile" */ 'pages/UserProfile/UserProfile'
    ),
});
const Supporter = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"Supporter" */ 'pages/Supporter/Supporter'),
});
const ProfileImpact = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"ProfileImpact" */ 'pages/ProfileImpact/ProfileImpact'
    ),
});
const ProfileImpactOrganization = Loadable({
  loading: Spinner,
  loader: () =>
    import('pages/ProfileImpactOrganization/ProfileImpactOrganization'),
});
const Faq = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"Faq" */ 'pages/Faq/Faq'),
});
const Introduction = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"Introduction" */ 'pages/Introduction/Introduction'
    ),
});
const Welcome = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"Registration" */ 'pages/Registration/components/Welcome/Welcome'
    ),
});
const Checkout = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"Checkout" */ 'pages/Checkout/Checkout'),
});
const GiftCardCheckout = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"GiftCardCheckout" */ 'pages/Checkout/GiftcardCheckout'
    ),
});
const Verification = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"Verification" */ 'pages/Verification/Verification'
    ),
});
const VerificationGuest = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"VerificationGuest" */ 'pages/VerificationGuest/VerificationGuest'
    ),
});
const Logout = Loadable({
  loading: Spinner,
  loader: () => import(/* webpackChunkName:"Logout" */ 'pages/Logout/Logout'),
});
const NoSuccess = Loadable({
  loading: Spinner,
  loader: () =>
    import(/* webpackChunkName:"NoSuccess" */ 'pages/NoSuccess/NoSuccess'),
});
const MemberInvitation = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"MemberInvitation" */ 'pages/MemberInvitation/MemberInvitation'
    ),
});
const UserSettings = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"UserSettings" */ 'pages/UserSettings/UserSettings'
    ),
});
const UserSettingsCompany = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"UserSettingsCompany" */ 'pages/UserSettingsCompany/UserSettingsCompany'
    ),
});
const PasswordReset = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"PasswordReset" */ 'pages/PasswordReset/PasswordReset'
    ),
});
const PasswordResetAction = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"PasswordResetAction" */ 'pages/PasswordResetAction/PasswordResetAction'
    ),
});
const OrganizationSettings = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"OrganizationSettings" */ 'pages/OrganizationSettings/OrganizationSettings'
    ),
});
const ImpactPage = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"OrganizationSettings" */ 'modules/ImpactPage/pages/ImpactPagePage'
    ),
});
const ContentPage = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"OrganizationSettings" */ 'modules/ContentPage/pages/ContentPagePage'
    ),
});
const BlogDetailPage = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"OrganizationSettings" */ 'modules/Blog/pages/BlogDetailPage'
    ),
});
const BlogListingPage = Loadable({
  loading: Spinner,
  loader: () =>
    import(
      /* webpackChunkName:"OrganizationSettings" */ 'modules/Blog/pages/BlogListingPage'
    ),
});

class Pages extends Component {
  /* lastLocation = '';

  componentWillUpdate() {
    const { location, history } = this.props;
    const gtag = window.gtag;

    if (location.pathname === this.lastLocation) {
      return
    }


    if ((history.action === 'PUSH' || history.action === 'REPLACE') && typeof(gtag) === 'function') {
      console.log('PUSHING NOW');
      this.lastLocation = location.pathname;

      gtag('config', 'GA_TRACKING_ID', {
        'page_location': window.location.href,
        'page_path': location.pathname,
      });
    }
  } */

  render() {
    return (
      <Wrapper>
        <LastLocationProvider>
          <Helmet titleTemplate="impactory: %s" />
          <AppWrapper>
            <CookieConsent
              location="bottom"
              buttonText={translate('general', 'accept')}
              style={{
                background: '#fff',
                color: '#212529',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              contentStyle={{
                flex: '0 1 auto',
              }}
              buttonStyle={{
                backgroundColor: '#ff5d7e',
                color: '#fff',
                fontSize: '13px',
              }}
              expires={150}
            >
              {translate('general', 'cookie')}{' '}
              <a className={styles.link} target="_blank" href="/c/Datenschutz">
                {translate('footer', 'privacy')}.
              </a>{' '}
            </CookieConsent>

            <ScrollToTop>
              <Switch>
                {/* Welcome */}
                <Route exact path="/datenschutz" component={Redirector} />
                <Route exact path={HOME_ROUTE} component={Home} />
                <Route path={CATEGORY_DETAIL_ROUTE} component={Categories} />
                <Route path={WELCOME_ROUTE} component={Welcome} />
                <Route path={INTRODUCTION_PAGE} component={Introduction} />
                <Route path={FAQ_ROUTE} component={Faq} />
                {/* Cause */}
                <Route
                  path={ORGANIZATION_DETAIL_ROUTE}
                  component={Organization}
                />
                <Route
                  path={RESET_PASSWORD_ACTION_ROUTE}
                  component={PasswordResetAction}
                />
                <Route path={RESET_PASSWORD_ROUTE} component={PasswordReset} />
                {/* Donations */}
                <PrivateRoute
                  exact
                  path={CAUSE_EDIT_ROUTE}
                  scope={['analytics']}
                  component={CreateCause}
                />
                <PrivateRoute
                  exact
                  path={CAUSE_CREATE_ROUTE}
                  component={CreateCause}
                />
                <Route
                  exact
                  path={CAUSE_THANK_YOU_ROUTE}
                  component={CreateCauseThankyou}
                />

                <Route path={CAUSE_DONATION_ROUTE} component={Checkout} />
                <Route path={CAUSE_DETAIL_ROUTE} component={Cause} />
                <Route path={CAMPAIGN_DETAIL_ROUTE} component={Cause} />
                {/* Authentication */}
                <Route path={SIGN_UP_ROUTE} component={SignUp} />
                <GuestRoute
                  path={REGISTRATION_ROUTE}
                  component={Registration}
                />
                <Route path={SIGN_UP_GUEST_ROUTE} component={SignUpGuest} />
                <GuestRoute path={LOGIN_ROUTE} component={Login} />

                <Route path={THANK_YOU_ROUTE} component={ThankYou} />
                {/* Giftcard */}
                <Route exact path={GIFTCARD_ROUTE} component={GiftCard} />
                <Route
                  path={GIFTCARD_CHECKOUT_ROUTE}
                  component={GiftCardCheckout}
                />
                <Route
                  path={GIFTCARD_SUCCESS_ROUTE}
                  component={GiftCardSuccess}
                />
                {/* Profile Organization */}
                <Route
                  exact
                  path={ORGANIZATION_PROFILE_IMPACT_ROUTE}
                  component={ProfileImpactOrganization}
                />

                {/* Settings Organization */}

                <Route
                  path={ORGANIZATION_PROFILE_SETTINGS_ROUTE}
                  component={OrganizationSettings}
                />
                {/* Profile */}
                <PrivateRoute
                  exact
                  path={PROFILE_IMPACT_ROUTE}
                  component={ProfileImpact}
                />
                <PrivateRoute
                  exact
                  path={PROFILE_ROUTE}
                  component={UserProfile}
                />
                <Route exact path={SUPPORTER_ROUTE} component={Supporter} />
                {/* Settings */}
                <PrivateRoute path={USER_SETTINGS} component={UserSettings} />

                {/* Settings */}
                <PrivateRoute path={USER_SETTINGS} component={UserSettings} />
                <PrivateRoute
                  path={USER_COMPANY_SETTINGS}
                  component={UserSettingsCompany}
                />
                {/* Other */}
                <Route path={VERIFICATION_ROUTE} component={Verification} />
                <Route
                  path={VERIFICATION_GUEST_ROUTE}
                  component={VerificationGuest}
                />
                <Route path={INVITATION_ROUTE} component={MemberInvitation} />
                <Route path={LOGOUT_ROUTE} component={Logout} />
                <Route path={NO_SUCCESS_ROUTE} component={NoSuccess} />
                <Route
                  path={PAGE_NOT_FOUND}
                  component={NotFound}
                  status={404}
                />

                <Route path={IMPACT_PAGE} component={ImpactPage} />
                <Route path={CONTENT_PAGE} component={ContentPage} />

                <Route path={BLOG_DETAIL_PAGE} component={BlogDetailPage} />
                <Route exact path={BLOG_LISTING_PAGE} component={BlogListingPage} />

                <Route exact path="/:slug" component={ImpactPage} />
              </Switch>

              <Footer />
            </ScrollToTop>
          </AppWrapper>
        </LastLocationProvider>
      </Wrapper>
    );
  }
}

export default withRouter(Pages);
