import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SocialMediaFloater.module.scss';
import Container from '../AppContainer/Container';
import translate from '../../lang';
import { CATEGORY_BASE } from '../../routes';
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* interface SocialMediaFloaterPropType {
  position: string;
} */

const SocialMediaFloater /* : React.FC */ = (
  props /* : SocialMediaFloaterPropType */,
) => {
  return (
    <div className={styles.SocialMediaFloater}>

      <a href="https://www.facebook.com/impactory.org" target="_blank" className={styles.SMFFacebook}>
        <FontAwesomeIcon icon={faFacebookF} />
      </a>

      <a href="https://www.linkedin.com/company/impactoryorg/" target="_blank" className={styles.SMFLinkedin}>
        <FontAwesomeIcon icon={faLinkedinIn} />
      </a>

      <a href="https://www.instagram.com/impactory_org" target="_blank" className={styles.SMFInstagram}>
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </div>
  );
};

export default SocialMediaFloater;
