import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import styles from './LanguageSwitch.module.scss';
import Container from '../AppContainer/Container';
import translate, { getLanguage, languageToSwitch } from '../../lang';
import { CATEGORY_BASE } from '../../routes';
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLanguage, languageToDisplay, languageToDisplay, switchLanguage } from "../../lang";
import queryString from "query-string"

/* interface LanguageSwitchFloaterPropType {
  position: string;
} */

const LanguageSwitchFloater /* : React.FC */ = (
    props /* : LanguageSwitchFloaterPropType */,
) => {
    const language = getLanguage();
    const redirectUrl = "?lang=" + languageToSwitch[language];

    
    return (
        <div className={styles.LanguageSwitchFloater}>

            <a className={styles.LanguageSwitchButton} href={redirectUrl} onClick={(e) => {
                e.preventDefault();
                if(typeof window !== "undefined"){
                    switchLanguage(languageToSwitch[language]);
                    window.location.href = window.location.origin + window.location.pathname + redirectUrl
                }
            }}>
                {languageToDisplay[language]}
            </a>

        </div>
    );
};

export default LanguageSwitchFloater;
