import api from 'api';

/*
*******
Actions
*******
*/

export const logoutUser = () => dispatch => {
  dispatch({
    type: 'USER_LOGGED_OUT',
  });
};

export const getUser = () => dispatch => {
  dispatch({
    type: 'USER_REQUESTED',
  });
  return api
    .user()
    .self()
    .then(data =>
      dispatch({
        type: 'USER_RECEIVED',
        payload: { data },
      }),
    )
    .catch(() =>
      dispatch({
        type: 'USER_NOT_AUTHENTICATED',
      }),
    );
};

export const getOrganization = () => dispatch => {
  dispatch({
    type: 'ORGANIZATION_REQUESTED',
  });

  return api
    .organizations()
    .self()
    .then(data =>
      dispatch({
        type: 'ORGANIZATION_RECEIVED',
        payload: { data },
      }),
    )
    .catch(() =>
      dispatch({
        type: 'ORGANIZATION_NOT_AUTHENTICATED',
      }),
    );
};

export const getMenu = () => dispatch => {
  dispatch({
    type: 'MENU_REQUESTED',
  });
  api
    .menu()
    .get()
    .then(({ data }) =>
      dispatch({
        type: 'MENU_RECEIVED',
        payload: { data },
      }),
    );
};

export const addNotification = notification => dispatch => {
  dispatch({
    payload: { id: Math.random(), ...notification },
    type: 'ADD_NOTIFICATION',
  });
};

export const removeNotification = id => dispatch => {
  dispatch({
    payload: id,
    type: 'REMOVE_NOTIFICATION',
  });
};
export const resetStripeContext = () => dispatch => {
  dispatch({
    payload: undefined,
    type: 'SET_STRIPE_CONTEXT',
  });
};
export const setStripeContext = key => dispatch => {
  dispatch({
    payload: key,
    type: 'SET_STRIPE_CONTEXT',
  });
};
export const setKlarnaSelected = key => dispatch => {
  dispatch({
    payload: key,
    type: 'SET_KLARNA_SELECTED',
  });
};

/*
*******
Reducer
*******
*/
const initState = {
  user: null,
  waitForUserAuthentication: true,
  menu: [],
  stripeContext: undefined,
  notifications: [],
  organization: null,
  klarnaSelected: false,
};

export const app = (state = initState, action) => {
  switch (action.type) {
    case 'ORGANIZATION_RECEIVED':
      return {
        ...state,
        organization: {
          ...action.payload.data.data.data,
          ...action.payload.data.data.meta,
        },
      };
    case 'SET_STRIPE_CONTEXT':
      return {
        ...state,
        stripeContext: action.payload,
      };
    case 'SET_KLARNA_SELECTED':
      return {
        ...state,
        klarnaSelected: action.payload,
      };

    case 'USER_RECEIVED':
      return {
        ...state,
        user: {
          ...action.payload.data.data.data,
          ...action.payload.data.data.meta,
        },
        waitForUserAuthentication: false,
      };
    case 'USER_NOT_AUTHENTICATED':
      return {
        ...state,
        waitForUserAuthentication: false,
      };
    case 'MENU_RECEIVED':
      return {
        ...state,
        menu: action.payload.data,
      };
    case 'USER_LOGGED_OUT':
      return {
        ...state,
        user: null,
      };
    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            notification => notification.id !== action.payload,
          ),
        ],
      };
    default:
      return state;
  }
};
