// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SocialMediaFloater-module__SocialMediaFloater-34gUwl{border-radius:10px 0 0 10px;background:#4E77E7;box-shadow:-3px 0px 15px #0000004D;overflow:hidden}.SocialMediaFloater-module__SocialMediaFloater-34gUwl a{text-decoration:none;outline:none;padding:17px 0;display:block;text-align:center;width:50px;transition:background-color 0.3s ease-in-out}.SocialMediaFloater-module__SocialMediaFloater-34gUwl a svg{color:#fff}.SocialMediaFloater-module__SocialMediaFloater-34gUwl a.SocialMediaFloater-module__SMFFacebook-1htRZB:hover{background-color:#3b5998}.SocialMediaFloater-module__SocialMediaFloater-34gUwl a.SocialMediaFloater-module__SMFLinkedin-3NzRw5:hover{background-color:#0073b0}.SocialMediaFloater-module__SocialMediaFloater-34gUwl a.SocialMediaFloater-module__SMFInstagram-2RZZB0:hover{background-color:#c32d65}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/components/FloatNavigation/SocialMediaFloater.module.scss"],"names":[],"mappings":"AAAA,sDACE,2BAA2B,CAC3B,kBAAkB,CAClB,kCAAmC,CACnC,eAAe,CAJjB,wDAOI,oBAAqB,CACrB,YAAY,CACZ,cAAc,CACd,aAAa,CACb,iBAAiB,CACjB,UAAU,CACV,4CAA4C,CAbhD,4DAgBM,UAAU,CAhBhB,4GAoBM,wBAAwB,CApB9B,4GAwBM,wBAAwB,CAxB9B,6GA4BM,wBAAwB","file":"SocialMediaFloater.module.scss","sourcesContent":[".SocialMediaFloater {\n  border-radius:10px 0 0 10px;\n  background:#4E77E7;\n  box-shadow: -3px 0px 15px #0000004D;\n  overflow:hidden;\n\n  a {\n    text-decoration: none;\n    outline:none;\n    padding:17px 0;\n    display:block;\n    text-align:center;\n    width:50px;\n    transition:background-color 0.3s ease-in-out;\n\n    svg {\n      color:#fff;\n    }\n\n    &.SMFFacebook:hover {\n      background-color:#3b5998;\n    }\n\n    &.SMFLinkedin:hover {\n      background-color:#0073b0;\n    }\n\n    &.SMFInstagram:hover {\n      background-color:#c32d65;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"SocialMediaFloater": "SocialMediaFloater-module__SocialMediaFloater-34gUwl",
	"SMFFacebook": "SocialMediaFloater-module__SMFFacebook-1htRZB",
	"SMFLinkedin": "SocialMediaFloater-module__SMFLinkedin-3NzRw5",
	"SMFInstagram": "SocialMediaFloater-module__SMFInstagram-2RZZB0"
};
module.exports = exports;
