import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Welcome
export const HOME_ROUTE = '/';
export const CATEGORY_BASE = '/spendenzwecke';
export const CATEGORY_DETAIL_ROUTE = `${CATEGORY_BASE}/:slug?`;
export const WELCOME_ROUTE = '/welcome';

// TODO:
export const INTRODUCTION_PAGE = '/introduction/:slug?';
export const FAQ_BASE = '/faq';
export const FAQ_ROUTE = `${FAQ_BASE}/:slug?`;

// Cause
export const ORGANIZATION_BASE = '/organization';
export const ORGANIZATION_DETAIL_ROUTE = `${ORGANIZATION_BASE}/:slug`;

export const CAUSE_BASE = '/spendenzweck';
export const CAUSE_DETAIL_ROUTE = `${CAUSE_BASE}/:id`;
export const CAUSE_SUBMITTED_ROUTE = `/submitted/${CAUSE_BASE}/:id`;
export const CAUSE_DONATION_ROUTE = `${CAUSE_BASE}/:id/donate/:amount?`;
export const CAUSE_CREATE_ROUTE = `/create${CAUSE_BASE}`;
export const CAUSE_EDIT_ROUTE = `/edit${CAUSE_BASE}/:slug`;
export const CAUSE_THANK_YOU_ROUTE = `/thankyou${CAUSE_BASE}/:slug`;

export const CAMPAIGN_BASE = '/campaigns';
export const CAMPAIGN_DETAIL_ROUTE = `${CAMPAIGN_BASE}/:slug`;

// Blog
// export const BLOG_ROUTE = '/blog';
// export const BLOG_POST_ROUTE = '/blog/:slug';

// Profile
export const PROFILE_ROUTE = '/profile';
export const SUPPORTER_ROUTE = '/supporter/:name';
export const PROFILE_IMPACT_ROUTE = '/profile/impact';
export const USER_SETTINGS = '/profile/settings';
export const USER_SETTINGS_PROFILE = '/profile/settings/profile';
export const USER_SETTINGS_PAYMENT = '/profile/settings/payment';
export const USER_SETTINGS_ACCOUNT_SETTINGS =
               '/profile/settings/account-settings';

// Profile Company
export const PROFILE_COMPANY_ROUTE = '/profile/company';
export const PROFILE_COMPANY_IMPACT_ROUTE = `${PROFILE_COMPANY_ROUTE}/impact`;
export const USER_COMPANY_SETTINGS = `${PROFILE_COMPANY_ROUTE}/settings`;
export const USER_COMPANY_SETTINGS_PROFILE = `${PROFILE_COMPANY_ROUTE}/settings/profile`;
export const USER_COMPANY_SETTINGS_PAYMENT = `${PROFILE_COMPANY_ROUTE}/settings/payment`;
export const USER_COMPANY_SETTINGS_ACCOUNT_SETTINGS = `${PROFILE_COMPANY_ROUTE}/settings/account-settings`;

// Profile Organization
export const ORGANIZATION_PROFILE_BASE = '/profile/organization';
export const ORGANIZATION_PROFILE_SETTINGS_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings`;
export const ORGANIZATION_PAYMENT_SETTINGS_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/payments`;
export const ORGANIZATION_LEGAL_SETTINGS_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/legal`;
export const ORGANIZATION_PROFILE_GENERAL_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/general`;
export const ORGANIZATION_PROFILE_NOTIFICATIONS_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/notifications`;
export const ORGANIZATION_PROFILE_MEMBERS_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/members`;
export const ORGANIZATION_PROFILE_PERSONAL_ROUTE = `${ORGANIZATION_PROFILE_BASE}/settings/personal`;
export const ORGANIZATION_PROFILE_IMPACT_ROUTE = `${ORGANIZATION_PROFILE_BASE}/impact`;

export const INVITATION_ROUTE = '/invitation/:token';

export const GIFTCARD_ROUTE = '/giftcard';
export const GIFTCARD_CHECKOUT_ROUTE = '/giftcard/design/:slug/amount/:amount';
export const GIFTCARD_ORDER_ROUTE = '/giftcard/order/:amount/:design';
export const GIFTCARD_SUCCESS_ROUTE = '/v/:slug';

export const RESET_PASSWORD_ROUTE = '/password/reset';
export const RESET_PASSWORD_ACTION_ROUTE = `${RESET_PASSWORD_ROUTE}/:token`;

export const SIGN_UP_ROUTE = '/sign-up';
export const SIGN_UP_GUEST_ROUTE = '/sign-up-guest/:token';
export const REGISTRATION_ROUTE = '/registration';

export const VERIFICATION_ROUTE = '/verification/:token';
export const VERIFICATION_GUEST_ROUTE = '/verification-guest/:token';

export const NO_SUCCESS_ROUTE = '/nosuccess';
export const THANK_YOU_ROUTE = '/thank-you/:token';

export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const PAGE_NOT_FOUND = '/404';

// Impact Pages
export const IMPACT_PAGE = '/impact/:slug';
export const IMPACT_PAGE_CAUSES = '/spendenzwecke';

// Content Pages
export const CONTENT_PAGE_BASE = '/c';
export const CONTENT_PAGE = `${CONTENT_PAGE_BASE}/:slug`;

// Content Pages
export const BLOG_LISTING_PAGE = '/blog';
export const BLOG_DETAIL_PAGE_BASE = '/blog/';
export const BLOG_DETAIL_PAGE = `${BLOG_DETAIL_PAGE_BASE}:slug`;

export const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};
// eslint-disable-next-line react/prop-types
export const PropsRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => renderMergedProps(component, routeProps, rest)}
  />
);

// eslint-disable-next-line react/prop-types
const PrivateRouteComponent = ({ component: Component, ...rest }) => {
  const { user, waitForUserAuthentication } = rest;

  return (
    <Route
      {...rest}
      render={props =>
        waitForUserAuthentication || user ? (
          <Component {...props} />
        ) : (
          <div>
            <Redirect
              // eslint-disable-next-line
              to={{ pathname: '/login/user', state: { from: props.location } }}
            />
          </div>
        )
      }
    />
  );
};

// eslint-disable-next-line react/prop-types
const GuestRouteComponent = ({ component: Component, ...rest }) => {
  const { user, waitForUserAuthentication } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        waitForUserAuthentication || !user || user.guest ? (
          <Component {...props} />
        ) : (
          <div>
            <Redirect
              // eslint-disable-next-line
              to={{ pathname: '/' }}
            />
          </div>
        )
      }
    />
  );
};

export const GuestRoute = connect(state => ({
  user: state.app.user,
  waitForUserAuthentication: state.app.waitForUserAuthentication,
}))(GuestRouteComponent);

export const PrivateRoute = connect(state => ({
  user: state.app.user,
  waitForUserAuthentication: state.app.waitForUserAuthentication,
}))(PrivateRouteComponent);
