import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import store from 'store';
import * as Sentry from '@sentry/browser';

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN });
}

const rootElement = document.getElementById('root');
export const renderApp = AppComponent => {
  render(
    <Provider store={store}>
      <AppContainer>
        <AppComponent />
      </AppContainer>
    </Provider>,

    rootElement,
  );
};
