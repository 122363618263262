import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';

import { getUser, getMenu } from 'pages/App/App.state';
// import styles from 'pages/App/App.scss';



import 'assets/images/header/apple-icon-57x57.png';
import 'assets/images/header/apple-icon-60x60.png';
import 'assets/images/header/apple-icon-72x72.png';
import 'assets/images/header/apple-icon-76x76.png';
import 'assets/images/header/apple-icon-114x114.png';
import 'assets/images/header/apple-icon-120x120.png';
import 'assets/images/header/apple-icon-144x144.png';
import 'assets/images/header/apple-icon-152x152.png';
import 'assets/images/header/apple-icon-180x180.png';
import 'assets/images/header/android-icon-192x192.png';
import 'assets/images/header/favicon-32x32.png';
import 'assets/images/header/favicon-96x96.png';
import 'assets/images/header/favicon-16x16.png';
import 'assets/images/header/favicon.ico';

import 'assets/images/favicon/apple-icon-57x57.png';
import 'assets/images/favicon/apple-icon-60x60.png';
import 'assets/images/favicon/apple-icon-72x72.png';
import 'assets/images/favicon/apple-icon-76x76.png';
import 'assets/images/favicon/apple-icon-114x114.png';
import 'assets/images/favicon/apple-icon-120x120.png';
import 'assets/images/favicon/apple-icon-144x144.png';
import 'assets/images/favicon/apple-icon-152x152.png';
import 'assets/images/favicon/apple-icon-180x180.png';
import 'assets/images/favicon/android-icon-192x192.png';
import 'assets/images/favicon/favicon-32x32.png';
import 'assets/images/favicon/favicon-96x96.png';
import 'assets/images/favicon/favicon-16x16.png';

import 'assets/images/impactory-fb.jpg';
// import Button from 'components/Button/Button';
// import Notification from 'components/Notification/Notification';

import Pages from 'pages/App/Pages';
import PropTypes from 'prop-types';

import 'dayjs/locale/de';
import reduxDispatcher from '../../services/reduxDispatcher';
import { getLanguage } from 'lang';

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    stripeContext: PropTypes.string,
  };

  static defaultProps = {
    stripeContext: null,
  };

  // state = {
  //   updateAvailable: false,
  // };

  componentDidMount() {
    // window.addEventListener('serviceWorkerUpdateAvailable', () => {
    //   this.setState({
    //     updateAvailable: true,
    //   });
    // });

    this.props.dispatch(getUser());
    this.props.dispatch(getMenu());

    reduxDispatcher.setDispatcher(this.props.dispatch);
  }

  // updateServiceWorker = () => {
  //   window.location.reload();
  // };

  render() {
    const { stripeContext } = this.props;
    // const { updateAvailable } = this.state;

    const language = getLanguage();


    if (stripeContext) {
      return (
        <StripeProvider
          key={stripeContext}
          stripeAccount={stripeContext}
          apiKey={STRIPE_API_KEY}
          locale={language}
        >
          <BrowserRouter>
            {/* <Fragment> */}
            {/* {updateAvailable && (
              <Notification
                type="information"
                position="bottom"
                message="Ein Update ist verfügbar"
                isSticky
              >
                <Button
                  onClick={this.updateServiceWorker}
                  theme="primary"
                  className={styles.updateButton}
                >
                  Update
                </Button>
              </Notification>
            )} */}
            <Pages />
            {/* </Fragment> */}
          </BrowserRouter>
        </StripeProvider>
      );
    }
    return (
      <StripeProvider apiKey={STRIPE_API_KEY} locale={language}>
        <BrowserRouter>
          {/* <Fragment> */}
          {/* {updateAvailable && (
            <Notification
              type="information"
              position="bottom"
              message="Ein Update ist verfügbar"
              isSticky
            >
              <Button
                onClick={this.updateServiceWorker}
                theme="primary"
                className={styles.updateButton}
              >
                Update
              </Button>
            </Notification>
          )} */}
          <Pages />
          {/* </Fragment> */}
        </BrowserRouter>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => {
  const { stripeContext } = state.app;
  return {
    stripeContext,
  };
};
export default connect(mapStateToProps)(App);
export { App as RawApp };
