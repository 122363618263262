// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".App__updateButton-3vpweO{margin-left:5px}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/pages/App/App.scss"],"names":[],"mappings":"AAAA,0BACE,eAAgB","file":"App.scss","sourcesContent":[".updateButton {\n  margin-left: 5px;\n}\n"]}]);
// Exports
exports.locals = {
	"updateButton": "App__updateButton-3vpweO"
};
module.exports = exports;
