import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/Wrapper/Wrapper.scss';

class Wrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default Wrapper;
