import React from 'react';
import { connect } from 'react-redux';
import Notification from '../Notification/Notification';
import { removeNotification } from '../../pages/App/App.state';
import styles from './AppWrapper.module.scss';

const AppWrapperNotifications /*: React.FC*/ = (
  props /*: AppWrapperPropType*/,
) => {
  const { notifications, dispatch } = props;

  if (!notifications || notifications.length < 1) {
    return null;
  }

  return (
    <div className={styles.AppWrapperNotifications}>
      {notifications.map(({ id, message, type, sticky, fade }) => (
        <Notification
          message={message}
          isSticky={sticky}
          type={type}
          fade={fade}
          key={id}
          dismissable
          onDismissClick={() => dispatch(removeNotification(id))}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  const { notifications } = state.app;
  return {
    notifications,
  };
};

export default connect(mapStateToProps)(AppWrapperNotifications);
