/**
 * ATTENTION: we cannot use core-js@3, but have to stick to core-js@2
 * until https://github.com/rjsf-team/react-jsonschema-form/pull/1686#issuecomment-633096488
 * is resolved...
 *
 * Once it is resolved, simply change this to upgrade to core-js@3:
 * @see https://git.duko.at/impactory/impactory-frontend/commit/36095a65ad3afde88d56de79c4feb3f52343dbc8
 */
import 'core-js';
import 'regenerator-runtime/runtime';

import { renderApp } from 'container';
import App from 'pages/App/App';
// import 'service-worker.js';

renderApp(App);

if (module.hot) {
  module.hot.accept(() => {
    renderApp(App);
  });
}
