const reduxDispatcher = {

  setDispatcher: (dispatcher) => {
    reduxDispatcher.theDispatcher = dispatcher;
  },

  dispatch: (action) => {
    if (reduxDispatcher.theDispatcher) {
      reduxDispatcher.theDispatcher(action);
    }
  }

};

export default reduxDispatcher;
