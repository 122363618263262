import React from 'react';
import NavBarLayer from './NavBarLayer';
import NavBarLayerItem from './NavBarLayerItem';
import {
  CAUSE_CREATE_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  ORGANIZATION_PROFILE_GENERAL_ROUTE,
  ORGANIZATION_PROFILE_IMPACT_ROUTE,
  PROFILE_IMPACT_ROUTE,
  SIGN_UP_ROUTE,
  USER_SETTINGS
} from "../../routes";
import { connect } from 'react-redux';
import translate from '../../lang';
import styles from "./NavBar.module.scss";
import { Link } from "react-router-dom";

// interface NavBarUserMenuPropType {
//   user: any; // USER TYPE
//   userType: 'guest' | 'ngo' | 'user';
//   onClick: () => void;
// }

const NavBarUserMenu = (props /*: NavBarUserMenuPropType*/) => {
  const { user, userType, onClick } = props;

  if (userType === 'guest') {
    return (
      <NavBarLayer>
        <NavBarLayerItem onClick={onClick} to={LOGIN_ROUTE}>
          {translate('menu', 'login')}
        </NavBarLayerItem>
        <NavBarLayerItem onClick={onClick} to={SIGN_UP_ROUTE}>
          {translate('menu', 'signup')}
        </NavBarLayerItem>
      </NavBarLayer>
    );
  }

  // navigation for ngos
  if (userType === 'ngo') {
    return (
      <NavBarLayer>
        <NavBarLayerItem
          onClick={onClick}
          to={ORGANIZATION_PROFILE_IMPACT_ROUTE}
        >
          {translate('menu', 'organization.dashboard')}
        </NavBarLayerItem>

        <NavBarLayerItem
          to={CAUSE_CREATE_ROUTE}
          onClick={onClick}
        >
          {translate('menu', 'organization.createcause')}
        </NavBarLayerItem>

        <NavBarLayerItem
          onClick={onClick}
          to={`/organization/${user.organization.slug}`}
        >
          {translate('menu', 'profile')}
        </NavBarLayerItem>
        <NavBarLayerItem
          onClick={onClick}
          to={ORGANIZATION_PROFILE_GENERAL_ROUTE}
        >
          {translate('menu', 'organization.settings')}
        </NavBarLayerItem>
        <NavBarLayerItem onClick={onClick} to={LOGOUT_ROUTE}>
          {translate('menu', 'organization.logout')}
        </NavBarLayerItem>
      </NavBarLayer>
    );
  }

  // just normal logged in user
  return (
    <NavBarLayer>
      <NavBarLayerItem onClick={onClick} to={PROFILE_IMPACT_ROUTE}>
        {translate('menu', 'myimpact')}
      </NavBarLayerItem>
      <NavBarLayerItem onClick={onClick} to={USER_SETTINGS}>
        {translate('menu', 'settings')}
      </NavBarLayerItem>
      <NavBarLayerItem onClick={onClick} to={LOGOUT_ROUTE}>
        {translate('menu', 'logout')}
      </NavBarLayerItem>
    </NavBarLayer>
  );
};

const mapStateToProps = state => {
  const { user } = state.app;
  return {
    user,
  };
};

export default connect(mapStateToProps)(NavBarUserMenu);
