// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".FloatNavigation-module__FloatNavigation-ko4eZz{display:none}@media (min-width: 990px){.FloatNavigation-module__FloatNavigation-ko4eZz{display:block;position:fixed;right:0;top:220px;z-index:800}}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/components/FloatNavigation/FloatNavigation.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAY,CACb,0BAIC,gDACE,aAAa,CACb,cAAc,CACd,OAAO,CACP,SAAS,CACT,WAAW,CACZ","file":"FloatNavigation.module.scss","sourcesContent":[".FloatNavigation {\n  display:none;\n}\n\n\n@media (min-width: 990px) {\n  .FloatNavigation {\n    display:block;\n    position:fixed;\n    right:0;\n    top:220px;\n    z-index:800;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"FloatNavigation": "FloatNavigation-module__FloatNavigation-ko4eZz"
};
module.exports = exports;
