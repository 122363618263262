import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

class Redirector extends Component {
  render() {
    if (this.props.location?.pathname?.toLowerCase() === '/datenschutz') {
      return <Redirect to="/c/Datenschutz" />;
    }
    if (this.props.location?.pathname?.toLowerCase() === '/nutzungsbedingungen') {
      return <Redirect to="/c/Nutzungsbedingungen" />;
    }

    console.log('NO RED FOUND: Could not redirect : '+ this.props.location.pathname);
    return null;
  }
}

export default withRouter(Redirector);
