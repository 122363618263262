import translations from 'translation.json';
import queryString from "query-string";

// small helpers
export const languageToDisplay = {
  "de": "EN",
  "en": "DE"
}

export const languageToDisplayMobile = {
  "de": "English",
  "en": "Deutsch"
}

export const languageToSwitch = {
  "en": "de",
  "de": "en"
}

// language switching
export const getLanguage = () => {

  let lang = "de";
  if(typeof window !== "undefined"){

    // localstorage with additonal check
    const storageLang = window.localStorage.getItem("locale");
    if(storageLang && Object.keys(languageToSwitch).includes(storageLang)){
      lang = storageLang
    }

    // query params with additional check
    const queryParams = queryString.parse(window?.location?.search);
    if(queryParams?.lang && Object.keys(languageToSwitch).includes(queryParams.lang)){
      lang = queryParams?.lang;
    }

  }

  return lang
}

export const switchLanguage = (selectedLanguage) => {
  if( typeof window !== "undefined"){
      window.localStorage.setItem("locale", selectedLanguage)
  }
}


// from https://www.npmjs.com/package/stringinject
// eslint-disable-next-line consistent-return
const stringInject = (str, data) => {
  if (typeof str === 'string' && data instanceof Array) {
    return str.replace(
      /({\d})/g,
      i => data[i.replace(/{/, '').replace(/}/, '')],
    );
  }
  if (typeof str === 'string' && data instanceof Object) {
    // eslint-disable-next-line consistent-return,guard-for-in,no-restricted-syntax
    for (const key in data) {
      return str.replace(/({([^}]+)})/g, i => {
        const key = i.replace(/{/, '').replace(/}/, ''); // eslint-disable-line no-shadow
        if (!data[key]) {
          return i;
        }

        return data[key] ?? 'No Translation';
      });
    }
  } else {
    return false;
  }
};

const translate = (namespace, key, data = null) => {
  const language = getLanguage();

  if (
    translations[language] &&
    translations[language][namespace] &&
    translations[language][namespace][key]
  ) {
    const translation = translations[language][namespace][key];
    if (data) {
      return stringInject(translation, data);
    }
    return translation;
  }
  console.error(
    'No translation found for Namespace: ' +
      namespace +
      ' and key: ' +
      key +
      ' with language: ' +
      language,
  );
  return namespace + '/' + key + '/' + language;
};
export default translate;
