// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Container-module__Container-1ELdfX{width:90%;max-width:1152px;margin:0 auto}.Container-module__Container-1ELdfX.Container-module__SmallerContainer-2xc75F{max-width:900px}.Container-module__Container-1ELdfX .Container-module__Container-1ELdfX{width:auto;max-width:none;margin:0}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/components/AppContainer/Container.module.scss"],"names":[],"mappings":"AAAA,oCACC,SAAS,CACT,gBAAgB,CAChB,aAAa,CAHd,8EAMI,eAAe,CANnB,wEAUI,UAAU,CACV,cAAc,CACd,QAAQ","file":"Container.module.scss","sourcesContent":[".Container {\n\twidth:90%;\n\tmax-width:1152px;\n\tmargin:0 auto;\n\n  &.SmallerContainer {\n    max-width:900px;\n  }\n\n  .Container {\n    width:auto;\n    max-width:none;\n    margin:0;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Container": "Container-module__Container-1ELdfX",
	"SmallerContainer": "Container-module__SmallerContainer-2xc75F"
};
module.exports = exports;
