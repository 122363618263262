// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LanguageSwitch-module__LanguageSwitchFloater-zamy3q{display:none}.LanguageSwitch-module__LanguageSwitchButton-FH6ALA{padding:14px;text-align:center;color:#4e77e7;font-size:15px;font-weight:bold;background:#fff;border-radius:10px 0 0 10px;box-shadow:-3px 0px 15px #0000004D;border:none;transition:all 0.2s ease-in-out;text-decoration:none}.LanguageSwitch-module__LanguageSwitchButton-FH6ALA:hover{background:#4e77e7;color:white}.LanguageSwitch-module__LanguageSwitchButton-FH6ALA:active{outline:none}@media (min-width: 990px){.LanguageSwitch-module__LanguageSwitchFloater-zamy3q{display:block;position:fixed;right:0;top:410px;z-index:800}}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/components/FloatNavigation/LanguageSwitch.module.scss"],"names":[],"mappings":"AAAA,qDACE,YAAY,CACb,oDAGC,YAAa,CACb,iBAAkB,CAClB,aAAc,CACd,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,2BAA4B,CAC5B,kCAAmC,CACnC,WAAY,CACZ,+BAAgC,CAChC,oBAAqB,CAXvB,0DAaI,kBAAmB,CACnB,WAAY,CAdhB,2DAiBI,YAAa,CACd,0BAKD,qDACE,aAAa,CACb,cAAc,CACd,OAAO,CACP,SAAS,CACT,WAAW,CACZ","file":"LanguageSwitch.module.scss","sourcesContent":[".LanguageSwitchFloater {\n  display:none;\n}\n\n.LanguageSwitchButton {\n  padding: 14px;\n  text-align: center;\n  color: #4e77e7;\n  font-size: 15px;\n  font-weight: bold;\n  background: #fff;\n  border-radius: 10px 0 0 10px;\n  box-shadow: -3px 0px 15px #0000004D;\n  border: none;\n  transition: all 0.2s ease-in-out;\n  text-decoration: none;\n  &:hover {\n    background: #4e77e7;\n    color: white;\n  }\n  &:active {\n    outline: none;\n  }\n}\n\n\n@media (min-width: 990px) {\n  .LanguageSwitchFloater {\n    display:block;\n    position:fixed;\n    right:0;\n    top:410px;\n    z-index:800;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"LanguageSwitchFloater": "LanguageSwitch-module__LanguageSwitchFloater-zamy3q",
	"LanguageSwitchButton": "LanguageSwitch-module__LanguageSwitchButton-FH6ALA"
};
module.exports = exports;
