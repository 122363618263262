import React from 'react';
import styles from './NavBarLayer.module.scss';
import { Link } from "react-router-dom";

/*interface NavBarLayerWrapperPropType {
  children: ReactNode | ReactNodeArray;
  position?: 'left' | 'right';
  mainLinkLabel: string;
  mainLinkTo: string;
  mainLinkClassName: string;
  closeLayer: () => void;
}*/

const NavBarLayerWrapper /*: React.FC*/ = (props /*: NavBarLayerWrapperPropType*/) => {
  const { children, position, mainLinkClassName, mainLinkLabel, mainLinkTo, closeLayer } = props;

  return (
    <div className={`${styles.NavBarLayerWrapper} ${(position === 'right') ? styles.NavBarLayerWrapperRight : ''}`}>
      {!mainLinkTo ? null :
       <Link to={mainLinkTo} onClick={closeLayer} className={mainLinkClassName}>{mainLinkLabel}</Link>
      }

      {children}
    </div>
  );
};

export default NavBarLayerWrapper;
