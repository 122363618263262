import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { app } from 'pages/App/App.state';

/* eslint no-underscore-dangle: ["error", { "allow": ["__PRELOADED_STATE__", "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */

const reducers = combineReducers({ app });
const preloadedState = window.__PRELOADED_STATE__ || {};
const middleware = [promise(), thunk];

const composeEnhancers =
  (DEV_TOOLS ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const appliedMiddleware = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, preloadedState, appliedMiddleware);
export default store;
