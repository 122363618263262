import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Spinner/Spinner.scss';
import Icon from 'components/Icon/Icon';
import { spinner } from 'components/Icon/list';

class Spinner extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  static defaultProps = {
    text: null,
  };

  render() {
    const { text } = this.props;

    return (
      <div className={styles.spinnWrapper}>
        <div className={styles.iconWrapper}>
          <Icon glyph={spinner} className={styles.icon} />
        </div>
        {text && <div className={styles.textWrapper}>{text}</div>}
      </div>
    );
  }
}

export default Spinner;
