import React from 'react';
import styles from './NavBarLayer.module.scss';

/*interface NavBarLayerPropType {
  children: ReactNode | ReactNodeArray;
  columns?: number;
  hideOnMobile?: number;
}*/

const NavBarLayer /*: React.FC*/ = (props /*: NavBarLayerPropType*/) => {
  const { columns, children, hideOnMobile } = props;

  const colClassName = `NavBarLayerColumns-${columns}`;
  let wrapperClassString = '';

  if (hideOnMobile) {
    wrapperClassString += ` ${styles.NavBarLayerHideOnMobile}`;
  }

  return (
    <div
      className={`${styles.NavBarLayer} ${styles[colClassName]} ${wrapperClassString}`}
    >
      <div className={`${styles.NavBarLayerInner}`}>{children}</div>
    </div>
  );
};

export default NavBarLayer;
