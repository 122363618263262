import React/*, { ReactNode, ReactNodeArray }*/ from 'react';
import styles from './Container.module.scss';
/*
interface IContainerPropType {
	children: ReactNode | ReactNodeArray;
	smaller?: boolean;
}*/

const Container/*: React.FC*/ = (props/*: IContainerPropType*/) => {
	return (
		<div className={`${styles.Container} ${props.smaller ? styles.SmallerContainer : ''}`}>
			{props.children}
		</div>
	);
};

export default Container;
