import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Notification/Notification.scss';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { close } from 'components/Icon/list';

class Notification extends Component {
  static propTypes = {
    message: PropTypes.node,
    children: PropTypes.node,
    type: PropTypes.oneOf(['warning', 'error', 'success', 'information']),
    paddedBottom: PropTypes.bool,
    dismissable: PropTypes.bool,
    isSticky: PropTypes.bool,
    fade: PropTypes.number,
    onDismissClick: PropTypes.func,
  };

  static defaultProps = {
    message: '',
    children: null,
    type: 'information',
    paddedBottom: false,
    fade: 0,
    dismissable: false,
    isSticky: false,
    onDismissClick: () => {},
  };

  state = {
    dismissed: false,
  };

  componentDidMount() {
    if (this.props.fade !== 0) {
      setTimeout(() => {
        this.setState({ dismissed: true });
        this.props.onDismissClick();
      }, this.props.fade);
    }
  }

  onDismissClick = () => {
    this.setState({ dismissed: true });
    this.props.onDismissClick();
  };

  renderMessage() {
    const {
      type,
      message,
      paddedBottom,
      onDismissClick,
      isSticky,
      children,
      dismissable,
    } = this.props;
    const classes = classNames(styles[type], styles.wrapper, {
      [styles.paddedBottom]: paddedBottom,
      [styles.isSticky]: isSticky,
      [styles.colextension]: dismissable,
    });
    return (
      <div className={classes}>
        {message}
        {children}
        {dismissable && (
          <div
            className={styles.closeWrapper}
            role="presentation"
            onClick={onDismissClick}
          >
            <Icon glyph={close} className={styles.icon} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { dismissed } = this.state;
    if (dismissed) {
      return <div />;
    }
    return <div className={styles.container}>{this.renderMessage()}</div>;
  }
}

export default Notification;
