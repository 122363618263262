// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Spinner__spinnWrapper-1TCv1u{display:flex;width:100%;align-items:center;justify-content:center}.Spinner__icon-1zakCw{animation:Spinner__spin-2DkC6H 1s linear infinite}@keyframes Spinner__spin-2DkC6H{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}\n", "",{"version":3,"sources":["/home/simonerich/Nodes/impactory/src/client/components/Spinner/Spinner.scss"],"names":[],"mappings":"AAAA,8BACE,YAAa,CACb,UAAW,CACX,kBAAmB,CACnB,sBAAuB,CACxB,sBAGC,iDAAkC,CACnC,gCAGC,GACE,sBAAuB,CAEzB,KACE,wBAAyB,CAAA","file":"Spinner.scss","sourcesContent":[".spinnWrapper {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon {\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"spinnWrapper": "Spinner__spinnWrapper-1TCv1u",
	"icon": "Spinner__icon-1zakCw",
	"spin": "Spinner__spin-2DkC6H"
};
module.exports = exports;
