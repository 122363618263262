import React from 'react';
import styles from './NavBarLayer.module.scss';
import { Link } from "react-router-dom";

/*interface NavBarLayerItemPropType {
  children: ReactNode | ReactNodeArray;
  to?: string;
  href?: string;
  onClick?: () => void;
}*/

const NavBarLayerItem /*: React.FC*/ = (props /*: NavBarLayerItemPropType*/) => {
  const { children, href, to, onClick } = props;

  return (
    <div className={`${styles.NavBarLayerItem} navbar-layer-item`}>
      {( to ) ?
       <Link to={to} onClick={onClick}>
         {children}
       </Link>
              :
       <a href={href} onClick={onClick}>
         {children}
       </a>
      }
    </div>
  );
};

export default NavBarLayerItem;
