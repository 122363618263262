import React from 'react';
import styles from './FloatNavigation.module.scss';
import SocialMediaFloater from './SocialMediaFloater';
import LanguageSwitchFloater from './LanguageSwitchFloater';

const FloatNavigation = () => {
  return (
    <div className={styles.FloatNavigation}>
      <SocialMediaFloater />
      <LanguageSwitchFloater/>
    </div>
  );
};

export default FloatNavigation;
