import React from 'react';
import styles from './NavBar.module.scss';
import Logo from './Logo.svg';

const NavBarLogo/*: React.FC*/ = (props) => {
	return (
		<div className={styles.NavBarLogoWrapper}>
			<a className={styles.NavBarLogoLink} href="/">
				<img className={styles.NavBarLogo} src={Logo} />
			</a>
		</div>
	);
};

export default NavBarLogo;
