import axios from 'axios';
import queryString from "query-string";
import { languageToSwitch, getLanguage } from 'lang';

const OAuthToken = 'OAuthToken';
const OAuthGuestAccessToken = 'OAuthGuestAccessToken';
const OAuthAccessToken = 'OAuthAccessToken';

class Client {
  constructor({ ...options }) {
    this.refreshingPromise = null;
    this.client = axios.create(options);
    this.client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    this.client.defaults.headers.common.Accept = 'application/json';

    this.init();
  }

  init() {
    /* eslint-disable no-param-reassign */
    this.client.interceptors.request.use(
      config => {
        const token = Client.getGuestToken() || Client.getAccessToken();
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      err => Promise.reject(err),
    );
    return this;
  }

  getClient() {

    this.client.defaults.headers.common.Locale = getLanguage();

    return this.client;
  }

  static getGuestToken() {
    try {
      return JSON.parse(sessionStorage.getItem(OAuthGuestAccessToken));
    } catch (error) {
      return sessionStorage.removeItem(OAuthGuestAccessToken);
    }
  }

  static saveGuestToken(accessToken) {
    sessionStorage.setItem(OAuthGuestAccessToken, JSON.stringify(accessToken));
  }

  static getAccessToken() {
    try {
      return JSON.parse(localStorage.getItem(OAuthAccessToken));
    } catch (error) {
      return localStorage.removeItem(OAuthAccessToken);
    }
  }

  static saveToken(accessToken) {
    sessionStorage.removeItem(OAuthGuestAccessToken);
    localStorage.removeItem(OAuthToken);
    localStorage.setItem(OAuthAccessToken, JSON.stringify(accessToken));
  }

  static removeToken() {
    sessionStorage.removeItem(OAuthGuestAccessToken);
    return localStorage.removeItem(OAuthAccessToken);
  }

  countries() {
    return {
      all: () => this.getClient().get('/api/countries'),
    };
  }

  causes(slug = null) {
    return {
      all: () => this.getClient().get('/api/causes'),
      take: take =>
        this.getClient().get('/api/causes', {
          params: {
            take,
            active_only: 1,
          },
        }),
      fetchMulti: params =>
        this.getClient().get('/api/causes', { params: params }),

      store: data => this.getClient().post(`/api/causes`, data),
      update: data => this.getClient().put(`/api/causes/${slug}`, data),
      toggleStatus: () => this.getClient().post(`/api/causes/${slug}/toggle`),
      get: cause => this.getClient().get(`/api/causes/${cause}`),
      analytics: data => this.getClient().get(`/api/causes/${slug}/analytics`, data),
      donate: data => this.getClient().post(`/api/causes/${slug}/donations`, data),
      donateWithVoucher: data =>
        this.getClient().post(`/api/causes/${slug}/donations/voucher`, data),
      byTag: (tag, query, page) =>
        this.getClient().get('/api/causes', {
          params: {
            active_only: 1,
            tag,
            query,
            page,
          },
        }),
    };
  }

  checkout() {
    return {
      initPayment: data => this.getClient().post(`/api/payment/init`, data),
      getIntent: data => this.getClient().post(`/api/payments/get-intent`, data),
      finish: data => this.getClient().post(`/api/payments/finish`, data),
    };
  }

  campaigns(campaignSlug) {
    return {
      toggleStatus: () =>
        this.getClient().post(`/api/campaigns/${campaignSlug}/toggle`),
      get: slug => this.getClient().get(`/api/campaigns/${slug}`),
      store: data => this.getClient().post(`/api/campaigns`, data),
      update: data => this.getClient().put(`/api/campaigns/${campaignSlug}`, data),
      analytics: data =>
        this.getClient().get(`/api/campaigns/${campaignSlug}/analytics`, data),
    };
  }

  verify() {
    return {
      get: token =>
        this.getClient().get(`/api/auth/register/verifyteammember/${token}`),
    };
  }

  vouchers() {
    return {
      get: slug => this.getClient().get(`/api/vouchers/${slug}`),
      store: data => this.getClient().post(`/api/vouchers`, data),
      status: code => this.getClient().post(`/api/vouchers/${code}/status`),
    };
  }

  donations() {
    return {
      get: token => this.getClient().get(`/api/donations/${token}`),
    };
  }

  cards() {
    return {
      delete: id => this.getClient().delete(`/api/cards/${id}`),
    };
  }

  giftCards() {
    return {
      all: () => this.getClient().get(`/api/giftcards`),
      get: slug => this.getClient().get(`/api/giftcards/${slug}`),
    };
  }

  tags() {
    return {
      all: () => this.getClient().get('/api/tags'),
      list: () => this.getClient().get('/api/tags/list'),
    };
  }
  //
  // posts() {
  //   return {
  //     all: () => this.getClient().get('/api/posts'),
  //     get: slug => this.getClient().get(`/api/posts/${slug}`),
  //   };
  // }

  pages() {
    return {
      all: () => this.getClient().get('/api/pages'),
      get: slug => this.getClient().get(`/api/pages/${slug}`),
    };
  }

  content_banners() {
    return {
      get: position => this.getClient().get(`/api/v2/content-banners/${position}`),
    };
  }

  content_pages() {
    return {
      get: slug => this.getClient().get(`/api/v2/content-pages/${slug}?platform=pwa`),
    };
  }

  blog_entries() {
    return {
      fetch: () => this.getClient().get(`/api/v2/blog-posts`),
      get: slug => this.getClient().get(`/api/v2/blog-posts/${slug}`),
    };
  }

  impactpages() {
    return {
      get: slug => this.getClient().get(`/api/v2/impact-pages/${slug}`),
      campaigns: slug => this.getClient().get(`/api/v2/impact-pages/${slug}/campaigns`),
    };
  }

  menu() {
    return {
      get: () => this.getClient().get('/api/menu'),
    };
  }

  invites() {
    return {
      store: emails =>
        this.getClient().post('api/organization-invites', { members: emails }),
    };
  }

  organizations() {
    return {
      // TODO: api route for random organizations for logo slider
      all: () => this.getClient().get('api/organizations'),
      payment: token =>
        this.getClient().post('/api/organizations/payment', { token }),
      stripeAccountLink: baseRoute =>
        this.getClient().get(`/api/organizations/payment?basePath=${baseRoute}`),
      self: () => this.getClient().get('api/organizations/self'),
      update: data => this.getClient().put('api/organizations/self', data),
      download: () =>
        this.getClient().get(`api/organizations/download`, {
          responseType: 'blob',
        }),
      get: (slug, options = {}) =>
        this.getClient().get(`api/organizations/${slug}`, options),
    };
  }

  user() {
    return {
      login: data =>
        this.getClient().post('api/login', {
          data,
        }),
      self: () => this.getClient().get('/api/users/self'),
      show: name => this.getClient().get(`/api/users/${name}`),
      update: data => this.getClient().put(`/api/users/self`, data),
    };
  }

  guest() {
    return {
      register: data =>
        this.getClient().post('api/auth/register-guest', {
          email: data.email,
          newsletter: data.newsletter ? 1 : 0,
        }),
      get: id => this.getClient().get(`/api/users/guest/${id}`),
    };
  }

  password() {
    return {
      email: email =>
        this.getClient().post('/api/password/email', {
          email,
        }),
      reset: data => this.getClient().post('/api/password/reset', data),
    };
  }

  verifications() {
    return {
      confirm: code =>
        this.getClient().post('/api/auth/confirm', {
          code,
        }),
      resend: () => this.getClient().get(`/api/email/resend`),
    };
  }
}

export default Client;
