import React, { useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import { Helmet } from 'react-helmet';
import styles from './AppWrapper.module.scss';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import AppWrapperNotifications from './AppWrapperNotifications';
import FloatNavigation from "../FloatNavigation/FloatNavigation";
import { languageToSwitch, getLanguage } from 'lang';

/*interface AppWrapperPropType {
	children: React.ComponentElement<any, any>;
}*/

const AppWrapper /*: React.FC*/ = (props /*: AppWrapperPropType*/) => {
  useEffect(() => {
    const { location } = props;

    const lang = getLanguage();

    if (lang && Object.keys(languageToSwitch).includes(lang)) {
      if (typeof document !== "undefined") {
        document?.documentElement?.setAttribute('lang', lang);
      }
      localStorage.setItem('locale', lang);
    }
  }, []);
  return (
    <div>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins&family=Muli&family=Open+Sans:wght@600&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <NavBar />

      <p>&nbsp;</p>

      <AppWrapperNotifications />

      {props.children}

      <FloatNavigation />
    </div>
  );
};

export default withRouter(AppWrapper);
